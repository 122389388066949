import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Page = ({ data }) => {
  return (
    <Layout>
      <section className="md:pb-30 pt-12 pb-20 md:pt-16">
        <div className="container">
          <StyledContent>
            <h1>Terms of Use</h1>
            <h3>NOTICE</h3>
            <p>We have a responsibility to you, our customers, to be open and honest, to treat you fairly, and to be safe and secure. These principles affect everything we do. The way we help find you a mortgage is no exception. Please read these terms and conditions carefully. By accessing this site and any pages thereof, you agree to be bound by the terms and conditions below. If you do not agree to the terms and conditions below, do not access this site, or any pages thereof. If you have any queries, please don’t hesitate to ask.</p>

<h3>USE OF INFORMATION AND MATERIALS</h3>
<p>The information and materials contained in these pages, and the terms, conditions, and descriptions that appear, are subject to change. Unauthorized use of Nikkael Home Loans’ web site and systems including but not limited to unauthorized entry into Nikkael Home Loans’ systems, misuse of passwords, or misuse of any information posted on a site is strictly prohibited. Not all products and services are available in all geographic areas. Your eligibility for particular products and services is subject to final determination by Nikkael Home Loans.</p>

<h3>NO WARRANTY</h3>
<p>The information and materials contained in this site, including text, graphics, links or other items are provided “as is”, “as available”. Nikkael Home Loans does not warrant the accuracy, adequacy or completeness of this information and materials and expressly disclaims liability for errors or omissions in this information and materials. No warranty of any kind, implied, expressed or statutory including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with the information and materials.</p>

<h3>LIMITATION OF LIABILITY</h3>
<p>In no event will Nikkael Home Loans be liable for any damages, including without limitation direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with this site or any linked site or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if Nikkael Home Loans, or representatives thereof, are advised of the possibility of such damages, losses or expenses.</p>

<h3>COPYRIGHT</h3>
<p>The Nikkael Home Loans logo and all designs, text, graphics, pictures, information, data, software, sound files, other files and the selection and arrangement thereof (collectively, “Site Materials”) are the proprietary property of Nikkael Home Loans or its licensors or users and are protected by U.S. and international copyright laws.</p>

<h3>SUBMISSIONS</h3>
<p>To the extent allowed by law, all information submitted to Nikkael Home Loans via this site shall be deemed and remain the property of Nikkael Home Loans and Nikkael Home Loans shall be free to use, for any purpose, any idea, concepts, know-how or techniques contained in information a visitor to this site provides Nikkael Home Loans through this site. Nikkael Home Loans shall not be subject to any obligations of confidentiality regarding submitted information except as agreed by the Nikkael Home Loans entity having the direct customer relationship or as otherwise specifically agreed or required by law.</p>

<h3>AVAILABILITY</h3>
<p>This site is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to local law or regulation. By offering this site and information, or any products or services via this site, no distribution or solicitation is made by Nikkael Home Loans to any person to use this site, or such information, products or services in jurisdictions where the provision of this site and such information, products and services is prohibited by law.</p>

<h3>ADDITIONAL TERMS</h3>
<p>Certain sections or pages on this site may contain separate terms and conditions, which are in addition to these terms and conditions. In the event of a conflict, the additional terms and conditions will govern for those sections or pages.</p>

<h3>GOVERNING LAW</h3>
<p>Use of this site shall be governed by all applicable federal laws of the United States of America and the laws of the State of California.</p>
            <p>Last Updated October 1st. 2020</p>
          </StyledContent>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
